body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(to bottom right, #fbc2eb, #a6c0fe); /* Colorful background */
}

.game-container {
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.game-heading {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.player-selection {
  margin-bottom: 30px; /* Increased space from the rest of the content */
}

.player-buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px; /* Increased gap between buttons */
}

.player-button {
  font-size: 1.2em;
  padding: 10px 20px;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.player-button.selected {
  background-color: #ff4081;
}

.player-button:hover {
  background-color: #f50057;
}

.scores {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 40px; /* Space between scores and cards */
}

/* Ensure score boxes are responsive */
.score {
  font-size: 1.2em;
  padding: 10px;
  color: #fff;
  background-color: #333;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  width: 120px; /* Consistent width for scores */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.score .player-number {
  font-size: 1.2em;
  font-weight: bold;
}

.score .player-score {
  font-size: 1em;
}

.score.highlight {
  background-color: #ff4081; /* Highlight color for the current player's turn */
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjusted for smaller screens */
  gap: 10px;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

/* Ensure cards are responsive */
.card {
  width: 100px;
  height: 100px;
  background-color: #ddd;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: relative;
  transition: transform 0.5s;
}

.card.flip {
  transform: rotateY(180deg);
}

.card img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.card-back {
  width: 100%;
  height: 100%;
  background-color: #4caf50; /* Back of the card color */
  border-radius: 5px;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(8, 1fr); /* 8 cards per row on larger screens */
  }

  .game-heading {
    font-size: 3em; /* Larger heading on larger screens */
  }
}

@media (max-width: 767px) {
  .score {
    width: auto; /* Auto width for scores on smaller screens */
    margin: 0 10px; /* Margin for spacing */
  }

  .player-buttons-container {
    flex-direction: column; /* Stack buttons vertically on small screens */
  }

  .player-button {
    font-size: 1em;
    padding: 8px 16px;
  }

  .restart-button {
    font-size: 1em;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .card {
    width: 80px; /* Smaller cards on very small screens */
    height: 80px;
  }

  .game-heading {
    font-size: 2em; /* Smaller heading on very small screens */
  }
}

/* Restart button styling */
.restart-button {
  background-color: #ff4081; /* Restart button color */
  color: #fff;
  border: none;
  padding: 12px 24px; /* Adjusted padding */
  font-size: 1.2em;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-top: 40px; /* Increased space between cards and restart button */
  transition: background-color 0.3s ease;
}

.restart-button:hover {
  background-color: #f50057; /* Darker color on hover */
}
/* Congratulatory message styling */
.congratulation-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensures it appears on top */
  margin-bottom: 50px; /* Adjust this value to create space below the message */
}
.card-grid {
  margin-bottom: 70px;
}